import React, { useEffect } from 'react'
import Disclaimer from './Disclaimer'
import { useLocation } from 'react-router-dom';
export default function TermsAndConditions() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <div>
            {/* {console.log("in render")} */}
            <div className="" id="fullContent">

                <div className="city-select-div" style={{ display: 'none' }}>
                    <div className="search">
                        {/* <input type="text" onChange={callOnChange()} placeholder="Enter city" name="" value="" /> */}
                        {/* <input type="text" onChange={this.callOnChange()} placeholder="Enter city" name="" value="" /> */}

                        <span className="material-symbols-outlined">
                            search
                        </span>
                    </div>

                    <div className="cities">
                        <a href="/">Banglore</a>
                        <a href="/">Pune</a>
                        <a href="/">Mumbai</a>
                        <a href="/">Delhi</a>
                        <a href="/">Kolkata</a>
                        <hr />
                        <a href="/">Agra</a>
                        <a href="/">Ahemdabad</a>
                        <a href="/">Ambala</a>
                    </div>

                </div>
                {/* {console.log(loading)} */}

                <section>
                    <div className="">
                        <img src="../img/tnc.jpg" width="100%" alt="" />
                    </div>
                </section>

                <section id="genre">

                    <div className="container">
                        <br /><br />
                        <div className="heading">
                            <span className="material-symbols-outlined">
                                receipt_long
                            </span>
                            <h4 style={{ fontWeight: '1000' }}>Terms and Conditions</h4>
                        </div><br />
                        <div style={{ border: '2px solid #333C5D' }} className="secbox">
                            <div>

                                <strong>Terms and Conditions ("Terms")</strong><br />
                                Last updated: September 23, 2018
                                Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the website (the
                                "Service") operated by TournamentPlanner LiVE (TPLiVE) ("us", "we", or "our"). Your access to and use of the
                                Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors,
                                users and others who access or use the Service. By accessing or using the Service you agree to be bound by these
                                Terms. If you disagree with any part of the terms then you may not access the Service.
                                <br /><br />
                                <strong>Registration</strong><br />
                                When you register with us, you must provide us information that is accurate, complete, and current at all times.
                                Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your
                                registration on our Service. You are responsible for safeguarding the password or OTP that you use to access the
                                Service and for any activities or actions under your password or OTP, whether your password or OTP is with our
                                Service or a third-party service. You agree not to disclose your password or OTP to any third party. You must
                                notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                                <br /><br />
                                <strong>Links To Other Web Sites</strong><br />
                                Our Service may contain links to third-party web sites or services that are not owned or controlled by us. We
                                have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any
                                third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable,
                                directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
                                reliance on any such content, goods or services available on or through any such web sites or services. We
                                strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
                                services that you visit.
                                <br /><br />
                                <strong>Termination</strong><br />
                                We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason
                                whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their
                                nature should survive termination shall survive termination, including, without limitation, ownership
                                provisions, warranty disclaimers, indemnity and limitations of liability. We may terminate or suspend your
                                account immediately, without prior notice or liability, for any reason whatsoever, including without limitation
                                if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to
                                terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their
                                nature should survive termination shall survive termination, including, without limitation, ownership
                                provisions, warranty disclaimers, indemnity and limitations of liability.
                                <br /><br />
                                <strong>Governing Law</strong><br />
                                These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict
                                of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver
                                of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
                                remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between
                                us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the
                                Service.
                                <br /><br />
                                <strong>Changes</strong><br />
                                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
                                material, no notice is required to provide prior to any new terms taking effect. What constitutes a material
                                change will be determined at our sole discretion. By continuing to access or use our Service after those
                                revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms,
                                please stop using the Service.
                                <br /><br />
                                <strong>Contact Us</strong><br />
                                If you have any questions about these Terms, please contact us.
                                <br /><br />
                                TournamentPlanner LiVE (TPLiVE)<br />
                                Whatsapp: +91 8484 966 096<br />
                                Email: connect@tplive.in<br />

                                <br></br>
                            </div>
                        </div>
                        <br />
                        <Disclaimer></Disclaimer>
                        {/* <div className="heading">
                            <span className="material-symbols-outlined">
                                privacy_tip
                            </span>
                            <h4 style={{ fontWeight: '1000' }}>Disclaimer</h4>
                        </div><br />

                        TPLiVE (Tournament Planner LiVE) provided the www.tplive.in website as a service to public.
                        The information provided on the website is for general informational purposes only.
                        <br /><br />
                        TPLiVE is not responsible for any loss or damage of any kind arising out of use.
                        While the information contained within the site is periodically updated, no guarantee is given that
                        the information provided in this web site is correct, complete, and up-to-date.
                        Although the site may include links providing direct access to other internet resources,
                        including web sites, TPLiVE is not responsible for the accuracy, adequacy, validity,
                        reliability, availability or completeness of content of information contained in
                        these sites. Links from the website to third-party sites do not constitute an
                        endorsement by TPLiVE of the parties or their products and services.
                        The appearance on the Web site of advertisements and product or service
                        information does not constitute an endorsement by TPLiVE , and TPLiVE has
                        not investigated the claims made by any advertiser. Advertisements and
                        Product information is based solely on the content received from suppliers. */}


                    </div>
                    <br /><br />
                    <br />
                </section>
            </div>

        </div >
    )
}
