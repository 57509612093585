import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
// import { functions } from '../firebase.js'
// import { httpsCallable } from "firebase/functions";
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
import EventPartcipantCard from './EventPartcipantCard'
import { useNavigate } from 'react-router-dom';

import '../css/EventDetails.css'
import '../css/EventParticipation.css'
import EventCategoryParticipant from './EventCategoryParticipant';

export default function EventParticipants() {
    const { state } = useLocation();
    const { calledFrom, eventDetails, entryCount, uniqueParticipantDetails, participantDetails, participantCount, selCategory } = state;

    const [loading, setLoading] = useState(false);
    const [pList, setPList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(selCategory ? selCategory : 'All');
    const navigate = useNavigate();
    // console.log('uniqueParticipantDetails', uniqueParticipantDetails);
    const [localUniqueParticipantDetails, setLocalUniqueParticipantDetails] = useState(uniqueParticipantDetails);
    const localParticipantDetails = participantDetails;
    const [searchKey, setSearchKey] = useState('');
    function sortPlayerList(playerlist) {
        console.log('playerlist', playerlist);
        playerlist && playerlist.sort((a, b) => {
            let fa = a.PlayerName.toLowerCase(),
                fb = b.PlayerName.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        return playerlist;
    }

    // const memoAlphaParticipant = useMemo(() => {
    function getPlayer(playerlist) {

        let objList = [];
        let firstChar = null;
        let charList = [];
        let index = -1;
        // console.log('playerlist', playerlist);
        playerlist = sortPlayerList(playerlist);
        // playerlist && playerlist.sort((a, b) => {
        //     let fa = a.PlayerName.toLowerCase(),
        //         fb = b.PlayerName.toLowerCase();

        //     if (fa < fb) {
        //         return -1;
        //     }
        //     if (fa > fb) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // console.log('playerlist : ', playerlist);
        playerlist.forEach(element => {
            if (firstChar !== element.PlayerName.substring(0, 1)) {
                index++;
                charList.push(element);
                objList[index] = {
                    firstCharector: element.PlayerName.substring(0, 1),
                    playerList: charList
                };
                firstChar = element.PlayerName.substring(0, 1);
            } else {
                objList[index].playerList.push(element)

            }
            charList = [];
        });
        // console.log(objList);
        setPList(objList);
    }

    useEffect(() => {
        getPlayer(localUniqueParticipantDetails);

        async function fetchData() {
            setLoading(true);
            if (selectedCategory !== '' && selectedCategory !== 'All') {
                onChangeEvent(selectedCategory);
            }
            setLoading(false);

        }
        fetchData();
    }, [uniqueParticipantDetails]);
    // let firstChar = null;
    function callParticipantDetails(playerID, playerUserID) {

        navigate("/PlayerParticipation", {
            state: {
                eventDetails: eventDetails,
                entryCount: entryCount,
                playerID: playerID,
                uniqueParticipantDetails: uniqueParticipantDetails,
                participantDetails: participantDetails,
                participantCount: participantCount,
                playerUserID: playerUserID
            }
        });

    }

    function onChangeEvent(option) {
        setSearchKey("");
        setSelectedCategory(option);
        // console.log('searchKey : ', searchKey)
        let lplayerList = [];
        let lParticipantList = [];
        let cnt = 0;
        if (option === 'All') {
            lParticipantList = localParticipantDetails;
        } else {
            lParticipantList = localParticipantDetails.filter(e => e.CategoryName === option);
        }

        lParticipantList.forEach(element => {
            if (!lplayerList.find(e => e.ParticipantID === element.ParticipantID)) {
                cnt = cnt + 1;
                lplayerList.push({
                    ParticipantID: element.ParticipantID,
                    PlayerName: element.ParticipantName,
                    ParticipantUserID: element.PlayerUserID
                })
            }
            if (element.PartnerPlayerID !== '') {
                if (!lplayerList.find(e => e.ParticipantID === element.PartnerPlayerID)) {
                    lplayerList.push({
                        ParticipantID: element.PartnerPlayerID,
                        PlayerName: element.PartnerPlayerName,
                        ParticipantUserID: ''
                    })
                }
            }

        });

        setLocalUniqueParticipantDetails(lParticipantList);
        getPlayer(lplayerList);

    }

    function searchPlayer() {
        let lplayerList = [];
        if (searchKey !== "") {
            setSelectedCategory('All');
        }
        let lParticipantList = [];
        lplayerList = uniqueParticipantDetails.filter(e => e.PlayerName.toUpperCase().includes(searchKey.toUpperCase()));
        setLocalUniqueParticipantDetails(lParticipantList);
        getPlayer(lplayerList);

    }

    return (
        <>
            <div className="container-fluid">

                <div className="row no-gutters">
                    <div className="col-lg-8 col-md-8 col-sm-12">

                        {eventDetails && <EventDetailsMenu calledFrom='Participant'
                            eventID={eventDetails.Eventid}
                            eventDetails={eventDetails}
                            entryCount={entryCount}
                            uniqueParticipantDetails={uniqueParticipantDetails}
                            participantDetails={participantDetails}
                            participantCount={participantCount}
                        />}
                        {loading && <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_27H8l4.json" background="transparent" speed="1" loop autoplay></lottie-player>}

                        <div>
                            <div className="" style={{ position: 'relative', zIndex: '5' }}>

                                <div className='total-participants-heading-div'>

                                    <div className='total-participants-search' style={{ position: 'relative', top: '-22px' }}>
                                        <input type='text' placeholder='Search Player Name...' onChange={(e) => {
                                            setSearchKey(e.target.value)
                                            searchPlayer()
                                        }}
                                            value={searchKey} ></input>
                                        <span className="material-symbols-outlined" onClick={searchPlayer}>
                                            search
                                        </span>
                                    </div>

                                    <div className="row no-gutters" style={{ width: '40%', position: 'relative', padding: '20px 10px 10px 10px' }}>
                                        <select name="" style={{ padding: '8px 50px 8px 5px', background: '#fff' }} className="total-participants-select" id="" value={selectedCategory}
                                            onChange={(e) => { onChangeEvent(e.target.value) }} >
                                            <option value="All" >All  Categories</option>
                                            {eventDetails && eventDetails.CategoryDetails && eventDetails.CategoryDetails.map((cat) => {
                                                return <option value={cat.CategoryName} key={cat.CategoryName}>{cat.CategoryName}</option>

                                            })}

                                        </select>

                                        <span style={{ top: '30%' }} className="material-symbols-outlined total-participants-select-icon">
                                            tune
                                        </span>

                                    </div>

                                </div>

                                {selectedCategory !== 'All' && <EventCategoryParticipant
                                    selectedCategory={selectedCategory}
                                    eventDetails={eventDetails}
                                    // playerList={pList}
                                    localParticipantDetails={localParticipantDetails}></EventCategoryParticipant>}
                                {selectedCategory === 'All' && <div className="row no-gutters">


                                    {pList && pList.length > 0 && pList.map((playerList) => {
                                        // console.log('playerList', playerList);
                                        return <EventPartcipantCard key={playerList.firstCharector} pList={playerList} eventID={eventDetails.Eventid} callParticipantDetails={callParticipantDetails} />
                                    })}
                                    {pList && pList.length === 0 && <span> No Participants</span>}
                                    {loading && <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_27H8l4.json" background="transparent" speed="1" loop autoplay></lottie-player>}

                                </div>}
                            </div>
                        </div>

                    </div>

                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}
                    {/* {eventDetails && <EDAboutEvent eventDetails={eventDetails} />} */}
                </div>
            </div >
            {/* <div className="container-fluid">
                <div className="row no-gutters">
                    {eventDetails && <EventDetailsMenu eventDetails={eventDetails}
                        calledFrom='Participant'
                        participantCount={setUniqueParticipantDetails.length}
                        participantDetails={uniqueParticipantDetails}
                        isLoading={loading} />}
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}
                </div>
            </div> */}
        </>
    )
}
