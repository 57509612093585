import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import SignIn from './SignIn';

export default function ProfileGeneral() {
    const { user, logout } = useUserAuth();
    const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    const [showLogout, setShowLogout] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [signinFlag, setSigninFlag] = useState(false);

    const navigate = useNavigate();
    function setSignIn(flag, userData) {
        console.log(flag)
        setSigninFlag(flag);
        setUserDetails(userData);
    }

    useEffect(() => {
        // getPlayerList();
        if (user.isLoggedIn && userDetails !== null) {
            if (user.userInfo) {
                // console.log('userDetails: ', userDetails);
                setSigninFlag(true)

            }
        }
        else {
            // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            // navigate("/PhoneSignUp", { state: { url: '' } });
            setSigninFlag(false)

        }
    }, [userDetails, user])

    const handleLogOut = async () => {
        // try {
        //     if (window.confirm('Are you sure you wish to Logout !!')) {
        //         await logout();
        //         navigate("/")
        //     }

        // } catch (err) {

        // }

        setShowLogout(true);
    }

    return (
        <>
            <br></br>
            {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>}

            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                <lottie-player src='https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json' background='transparent'
                    speed="1" style={{ width: '50%', height: '50%' }} loop autoplay />
            </div >

            <div className={showLogout ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 >
                        <strong>Are you sure you wish to logout?</strong>

                    </h1>

                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={() => {
                            logout();
                            navigate("/")

                        }}>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                            setShowLogout(false);

                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>


            <div className='profile-notification-div' >

                <div className='profile-notification-div-inner' onClick={() => navigate("/UserProfile", {
                    state: {
                        id: 1,
                        propsIsNew: true,
                        propsSelectedPlayer: ''
                    }
                })}>
                    <div>
                        <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                            add
                        </span>
                    </div>
                    <h1>Add Members</h1>
                </div>
                <div className='profile-notification-div-inner' onClick={() => navigate("/UserProfile", {
                    state: {
                        id: 2,
                        propsIsNew: false,
                        propsSelectedPlayer: userDetails.PlayerID
                    }
                })} >
                    <div>
                        <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                            person
                        </span>

                    </div>
                    <h1>Members</h1>
                </div>


                <div className='profile-notification-div-inner' onClick={() => handleLogOut()}>
                    <div>
                        <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                            logout
                        </span>
                    </div>
                    <h1>Logout</h1>
                </div>

            </div>

            <br></br>

            <div className='profile-cards'>
                <h1 className='profile-cards-heading'>MY PARTICIPATION</h1>

                <div className='profile-cards-inner padding-difference' onClick={() => {
                    console.log('clicked')
                    navigate('/ManageParticipant');
                }}>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            local_mall
                        </span>
                    </div>
                    <div className='profile-cards-inner-content' >
                        <h1>Manage Participation</h1>
                        <h2>Modify Your Current Participation</h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

                <div className='profile-cards-inner padding-difference'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            favorite
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Participation History</h1>
                        <h2>Your Past Participation Details</h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

            </div>

            <br></br>

            <div className='profile-cards'>
                <h1 className='profile-cards-heading'>Match Details</h1>

                <div className='profile-cards-inner'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            redeem
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Match Summary</h1>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

                <div className='profile-cards-inner'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            package
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Head 2 Head</h1>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>


            </div>

            <br></br>

            <div className='profile-cards'>
                <h1 className='profile-cards-heading'>SETTINGS</h1>

                <div className='profile-cards-inner padding-difference'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            translate
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Language</h1>
                        <h2>English</h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

                <div className='profile-cards-inner padding-difference' style={{ cursor: "pointer" }} onClick={() => navigate("/UserProfile", {
                    state: {
                        id: 2,
                        propsIsNew: false,
                        propsSelectedPlayer: userDetails.PlayerID
                    }
                })}>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            supervisor_account
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Profile</h1>
                        <h2>Change / Update Profile</h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>
                <div className='profile-cards-inner padding-difference'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            language
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Country</h1>
                        <h2>India</h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

                <div className='profile-cards-inner'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            move_to_inbox
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Wallet</h1>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>

            </div>

        </>
    )
}
