import React from 'react'
// import { Link } from 'react-router-dom'
export default function EventPartcipantCard(props) {
    function callParticipantDetails(player) {
        console.log('player : ', player);
        props.callParticipantDetails(player.ParticipantID, player.ParticipantUserID);
    }
    return (
        <>
            <div className="total-participants-outter-div">

                <div className='col-12'>
                    <div className="total-participants-div-letter">
                        <h1>{props && props.pList && props.pList.firstCharector}</h1>
                    </div>
                </div>
                <div className="row no-gutters" style={{ padding: '0 10px' }}>
                    {props.pList && props.pList.playerList.map((player) => {
                        return (
                            <div div className='col-4' key={player.ParticipantID} >
                                <div className="total-participants-div-content">
                                    <div style={{ cursor: 'pointer' }} onClick={() => callParticipantDetails(player)}>

                                        <span>
                                            {player.PlayerName}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )

                    })}
                </div>
            </div>
            {/* 

            <div className='col-4'>
                <div className="total-participants-div-content">
                    <div>
                        <a href="/">Aditya Tripathi</a>
                    </div>
                </div>
            </div>

            <div className='col-4'>
                <div className="total-participants-div-content">
                    <div>
                        <a href="/">Aditya Tripathi</a>
                    </div>
                </div>
            </div>

            <div className='col-4'>
                <div className="total-participants-div-content">
                    <div>
                        <a href="/">Aditya Tripathi</a>
                    </div>
                </div>
            </div>

            <div className='col-4'>
                <div className="total-participants-div-content">
                    <div>
                        <a href="/">Aditya Tripathi</a>
                    </div>
                </div>
            </div>

            <div className='col-4'>
                <div className="total-participants-div-content">
                    <div>
                        <a href="/">Aditya Tripathi</a>
                    </div>
                </div>
            </div> */}

            <br /><br />

            {/* 
            <div className="total-participants-div-letter">
                <h1>{props.pList.firstCharector}</h1>
            </div>
            <br /> */}

            {/* <div className="total-participants-div-content">
                {props.pList && props.pList.playerList.map((player) => {
                    return (<div>
                
                        <div onClick={() =>
                            callParticipantDetails(player)}>{player.PlayerName} </div>

                    </div>)


                })}

            
            </div> */}

        </>
    )
}
