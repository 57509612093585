import React, { useEffect, useState } from 'react'
import QRCodeComponent from './QRCodeComponent';
import { useNavigate } from 'react-router-dom';
import '../css/Ticket.css'
import '../css/UpdateEvent.css';
import '../css/AddEvent.css'
import { useRazorpay } from '../context/useRozarPay';
import { useUserAuth } from '../context/UserAuthcontext';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
// import GooglePayButton from '@google-pay/button-react'
import { usePhonepe } from '../context/usePhonepe';
import { useLocalStorage } from "../context/useLocalStorage";
import Loading from './Loading';
import PhonePeCheckout from '../context/PhonePeCheckout.js'
export default function EntryTicket(props) {
    const navigate = useNavigate();
    const { user } = useUserAuth();
    const { makePayment, paymentResponse, handleRefund } = useRazorpay();
    // const { makePayment, paymentResponse } = useRazorpay();

    // const { handlePayment, handleRefund, handlePaymentnew } = usePhonepe();

    // const { makePaymentPhonePe } = usePhonepe();
    const userDetails = useLocalStorage('userProfile', null);
    // const [registeredUser, setRegisteredUser] = useState(null);
    const [differentUser, setDifferentUser] = useState(false)
    const [action, setAction] = useState('');
    const [loading, setLoading] = useState(false);
    const [popupLoading, setPopupLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [partnerList, setPartnerList] = useState(null);
    const [partnerCode, setPartnerCode] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [newPaymentStatus, setNewPaymentStatus] = useState(props.participant.PaymentStatus)
    // console.log('props.participant.PaymentStatus', props, '::newPaymentStatus ::', newPaymentStatus)
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    let dt = new Date(props.event.EventStartDate._seconds * 1000);
    let withdrawDt = new Date(props.event.WithdrawalEndDate._seconds * 1000);
    let withdrawFlag = false;
    if (withdrawDt >= new Date()) {
        withdrawFlag = true;
    }
    let sDate = dt.toLocaleDateString("en-IN", options);
    let fee = Number(props.participant.Fees).toLocaleString('en-IN', curFormat)
    let convenienceCharge = props.event.ConvenienceCharge;

    let convenienceAmt = (convenienceCharge && convenienceCharge !== '' && convenienceCharge !== '0') ? Math.round((Number(props.participant.Fees) * Number(convenienceCharge) / 100)) : 0;
    let convenienceAmtTxt = Number(convenienceAmt).toLocaleString('en-IN', curFormat)
    let totalAmtTxt = (Number(convenienceAmt) + Number(props.participant.Fees)).toLocaleString('en-IN', curFormat);

    useEffect(() => {
        console.log(paymentResponse)
        let totalPayment = Number(convenienceAmt) + Number(props.participant.Fees);
        let status = '';
        console.log('paymentResponse : ', paymentResponse);
        if (paymentResponse) {
            console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse.paymentStatus.toUpperCase())
            status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';


            if (props.event.ConvenienceCharge && props.event.ConvenienceCharge > 0) {
                totalPayment = totalPayment + Number(totalPayment * props.event.ConvenienceCharge / 100);
            }
            if (status !== '' && status !== props.participant.PaymentStatus) {
                setNewPaymentStatus(status);
                // to be un commented updateTransactioninDB(paymentResponse.transactionID, totalPayment, status)
            }
        }

    }, [paymentResponse])

    async function updateTransactioninDB(transactionid, paymentAmount, paymentstatus) {
        setPopupLoading(true);
        // console.log('user', user, 'userDetails', userDetails)
        if (user && user.userInfo && userDetails) {


            const updatetransaction = httpsCallable(functions, "updateTransactionID");
            let para = {
                RegisteredID: props.participant.RegID,
                TransactionID: transactionid,
                PaymentAmount: paymentAmount,
                PaymentStatus: paymentstatus,

            };
            console.log('para', para);
            return updatetransaction(para)
                .then(async (result) => {
                    console.log('result', result)
                    // getParticipantList(selectedEvent.Eventid, searchEvent.EventCode);
                    // setPopupLoading(false);
                    // setShowPopup(false);
                })
        }
    }
    async function PhonePePayment() {
        let orderId = props.event.EventCode + '_' + props.participant.ParticipantID + + new Date().getTime();
        console.log('before phonepe call', 'props.participant.ParticipantName : ', props.participant.ParticipantName,
            'user.phoneNumber : ', user.userInfo.phoneNumber,
            'orderId ', orderId,
            'convenienceAmt : ', convenienceAmt,
            'props.participant.Fees : ', props.participant.Fees,
            'props.participant.RegID : ', props.participant.RegID
        )
        //save Order ID in DB
        let amt = Number(convenienceAmt) + Number(props.participant.Fees);
        updateTransactioninDB(orderId, amt, 'Pending')
        // await handlePayment(props.participant.ParticipantName,
        //     user.userInfo.phoneNumber,
        //     orderId,
        //     amt,
        //     JSON.stringify(props.participant.RegID)
        // ).then(async (e) => {
        //     console.log(e)
        //     // console.log('paymentResponse', paymentResponse);
        // });

    }
    async function RazorPayPayment() {
        let orderId = props.event.EventCode + '_' + props.participant.ParticipantID + + new Date().getTime();
        let totalPayment = Number(convenienceAmt) + Number(props.participant.Fees);

        if (props.event.ConvenienceCharge && props.event.ConvenienceCharge > 0) {
            totalPayment = totalPayment + Number(totalPayment * props.event.ConvenienceCharge / 100);
        }

        makePayment(orderId,
            Number(totalPayment),
            userDetails.UserName, 'Payment for Registration',
            userDetails && userDetails.UserEmail,
            user.phoneNumber,
            [props.participant.RegID]
        ).then(async (e) => {
            console.log(e, 'paymentResponse', paymentResponse);

        })
    }
    async function handlePaymentClick() {
        // console.log('user', user)
        if (!(user && user.isLoggedIn && user.userInfo)) {
            // console.log('user', user)

            props.setIsPayClicked(true);
        }
        else {
            console.log('user', user)

            let orderId = props.event.EventCode + '_' + props.participant.ParticipantID + + new Date().getTime();
            let amount = Number(convenienceAmt) + Number(props.participant.Fees);

            // handlePaymentnew(orderId, amount);

            if (userDetails && userDetails[0] && userDetails[0].RegisteredUser && userDetails[0].RegisteredUser.findIndex(e => e.PlayerID === props.participant.ParticipantID) >= 0) {
                // PhonePePayment();
                if (props.event) {
                    console.log('in if')
                    RazorPayPayment();
                } else {
                    console.log('in else')
                }


            } else {

                setDifferentUser(true);
                setShowPopup(true);
            }

        }

    };

    async function WithdrawEntry(participantDetails) {
        if (!(user && user.isLoggedIn && user.userInfo)) {

            var catDel = [];
            catDel.push(participantDetails.CategoryName);
            var para1 = {};
            para1 = {
                EventID: participantDetails.EventID,
                PlayerID: participantDetails.ParticipantID,
                DeleteCategoryList: [participantDetails.CategoryName],
            };
            console.log('para1', para1);
            // const ret1 = await httpsCallable(functions, "withdrawRegistration");
            // ret1(para1).then(async (result) => {

            // })
        }
    }


    const handleRefundEvent = async (obj) => {
        //uppdate the registration status as withdrawn
        // console.log('user', userDetails, 'props.participant.ParticipantID', props.participant.ParticipantID, 'userDetails[0].RegisteredUser', userDetails[0].RegisteredUser)
        // console.log('userDetails[0].RegisteredUser.findIndex(e => e.PlayerID === props.participant.ParticipantID)', userDetails[0].RegisteredUser.find(e => e.PlayerID === props.participant.ParticipantID))
        if (!(user && user.isLoggedIn && user.userInfo)) {
            props.setIsPayClicked(true);
        } else {
            if (userDetails && userDetails[0] && userDetails[0].RegisteredUser && userDetails[0].RegisteredUser.findIndex(e => e.PlayerID === props.participant.ParticipantID) >= 0) {
                // WithdrawEntry(obj);
                console.log('obj', obj);
                if (obj.PaymentStatus.toUpperCase() === 'COMPLETED') {
                    handleRefund(obj, 'Entry Withdraw ')
                }
            }
            else {
                setDifferentUser(true);
                setShowPopup(true);
                console.log('logged user is different  ')
            }
        }

    };

    async function updatePartnerDetailsPopup() {
        console.log('in updatePartnerDetails', props.participant)

        const partnerListQ = httpsCallable(functions, "getAllPartnerListForEvent");
        setLoading(true);

        var categoryList = props.event.CategoryDetails;
        console.log('categoryList : ', categoryList)
        var cat = categoryList.filter(e => e.CategoryName === props.participant.CategoryName);
        console.log('cat : ', cat)
        setPopupLoading(true);
        // console.log('partnerGender', partnerGender);
        let para = {
            EventCode: props.event.EventCode,
            PlayerID: props.participant.ParticipantID,
            CategoryName: props.participant.CategoryName,
            CategoryDetails: cat[0],
        };
        console.log('para', para);
        await partnerListQ(para)
            .then(async (result) => {
                console.log('result', result)
                setPartnerList(result.data);

                setShowPopup(true);
                setPopupLoading(false);

            })
    }


    function confirmActionPopup() {
        if (action === 'WithdrawEntry') {

            handleRefundEvent(props.participant);
            setShowPopup(false);
        } else if (action === 'UpdatePartner') {
            updatePartnerinDB();
        }
        // setShowPopup(false);
    }

    async function updatePartnerinDB() {
        setPopupLoading(true);

        const partnerListQ = httpsCallable(functions, "updatePartner");
        console.log('PartnerCode', partnerCode)
        let partnerName = partnerCode.name.split('-');
        console.log('partnerName', partnerName[0])
        let para = {
            EventID: props.participant.EventID,
            PlayerID: props.participant.ParticipantID,
            CategoryName: props.participant.CategoryName,
            PartnerID: partnerCode.code,
            PartnerName: partnerName[0],//partnerCode.name
        };
        console.log('para', para);
        return partnerListQ(para)
            .then(async (result) => {
                console.log('result', result)
                // getParticipantList(selectedEvent.Eventid, searchEvent.EventCode);
                setPopupLoading(false);
                setShowPopup(false);
            })
    }
    function cancleActionPopup() {
        setShowPopup(false);
    }
    // useEffect(() => {
    //     setNewPaymentStatus(props.participant.paymentStatus);
    //     console.log('paymentResponse', newPaymentStatus)

    // }, [props.participant.paymentStatus])
    useEffect(() => {
        if (userDetails && userDetails[0] && userDetails[0].RegisteredUser
            && userDetails[0].RegisteredUser.findIndex(e => e.PlayerID === props.participant.ParticipantID) >= 0) {
            setDifferentUser(false);
        }
        else {
            setDifferentUser(true);
        }
        setNewPaymentStatus(props.participant.PaymentStatus);
        console.log('props.participant.paymentStatus', props.participant, 'setNewPaymentStatus', newPaymentStatus)
        // console.log('differentUser', differentUser, 'userDetails[0].RegisteredUser', userDetails[0].RegisteredUser, 'props.participant.ParticipantID', props.participant.ParticipantID)

    }, [props.participant, props.event])
    return (
        <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                {popupLoading && <Loading></Loading>}
                {/* {console.log('entryDetails', entryDetails)} */}
                {differentUser && <div className='pop-up-div-inner'>
                    {!userDetails[0] ?
                        <h1> You have not yet logged in, please login to Withdraw  </h1>

                        : <>
                            <h1> You have logged in as different user, you will be able to modify participation for Registered
                                player under current login <strong>({userDetails[0] && userDetails[0].Phone})</strong></h1>
                            <hr />
                        </>}
                    {/* 
                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        <br></br>
                        <input type="text" value={'Partner will be changed to ' + partnerCode.name} onChange={(e) =>
                            console.log('on Change')} />
                    </div> */}

                    <div className='btn-div' style={{ alignContent: 'center' }}>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                        {!userDetails[0] &&
                            <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                                props.setLoginFlag(false);
                                setShowPopup(false);
                            }}>
                                <span>Login</span>
                            </button>

                        }
                    </div>


                </div>}

                {action === 'UpdatePartner' && !differentUser && !popupLoading && <div className='pop-up-div-inner'>

                    <h1>Change Partner <strong>{props.participant.CategoryName}</strong> for <strong>{props.participant.ParticipantName}</strong></h1>
                    <hr />

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        <label htmlFor="">Select</label>
                        <select name="" onChange={(sel) => {
                            // console.log('sel.target.text:', sel.target.selectedOptions[0].text, ':: sel.target.value:', sel.target.value)
                            setPartnerCode({
                                code: sel.target.value,
                                name: sel.target.selectedOptions[0].text
                            });
                        }}>
                            {/* {console.log(partnerList)} */}
                            {partnerList && partnerList.map((e) => {
                                let str = e.Phone ? e.Phone.slice(-4).padStart(e.Phone.length, '*') : '';
                                return <option key={e.id} value={e.PlayerID}>{e.UserName}-{str}</option>
                            })}
                        </select>
                    </div>

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        {/* <label htmlFor="">Input</label> */}
                        <br></br>
                        <input type="text" value={'Partner will be changed to ' + partnerCode.name} onChange={(e) =>
                            console.log('on Change')} />
                    </div>

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}

                {action === 'WithdrawEntry' && !differentUser && <div className='pop-up-div-inner'>
                    <h1 >
                        Are you sure you wish to withdraw for <br />
                        Event : <strong> {props.event.EventName} </strong><br></br>
                        Category : <strong>{props.participant.CategoryName}</strong> <br></br>
                        Player : <strong> {props.participant.ParticipantName}</strong> {(props.participant.PartnerPlayerName && props.participant.PartnerPlayerName !== '' && <strong> - {props.participant.PartnerPlayerName}</strong>)}
                    </h1>

                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}


            </div>



            <div className='ticket-card-outter'>
                <div className='ticket-card'>
                    <div className='event-id'>
                        <h5>{props.participant.CategoryName}</h5>
                    </div>
                    <div className='ticket-card-heading'>
                        <div style={{ paddingRight: '20px' }}>
                            <h1>{props.event.EventName}</h1>
                            <div>
                                <span className="material-symbols-outlined">
                                    {props.participant.EventType.toUpperCase() === 'SINGLE' ? 'person' : 'group'}
                                </span>
                                {/* <span className="material-symbols-outlined">
                                person
                            </span> */}
                                <h2>{props.participant.ParticipantName} <br />
                                    {props.participant.PartnerPlayerID !== '' ? props.participant.PartnerPlayerName : <><div style={{ color: ' #fff' }}>.</div></>
                                    }
                                </h2>


                            </div>
                            {!differentUser && (props.participant.EventType.toUpperCase() !== 'SINGLE' && props.participant.RegType === 'Self') ? <small onClick={(e) => {
                                setAction('UpdatePartner');
                                // setShowPopup(true);
                                updatePartnerDetailsPopup(props.participant)
                            }}>Change Partner</small> : ''}
                            {/* {differentUser && <><br></br><small style={{ color: 'red' }}>
                                Logged user is Different
                            </small></>} */}
                            {/* {props.participant. <small>Change Partner</small>} */}
                        </div>
                        {props.showQR && <div style={{ paddingLeft: '20px' }} onClick={() => {
                            navigate('/PlayerEntry/' + props.participant.PartnerPlayerID + "/" + props.event.EventID);
                        }}>
                            <QRCodeComponent value={'/PlayerEntry/' + props.participant.PartnerPlayerID + "/" + props.event.EventID} size={70}></QRCodeComponent>
                        </div>}
                    </div>
                    <div className='ticket-card-divider'>
                        <div></div>
                        <h3>TICKET</h3>
                        <div></div>
                    </div>
                    <div className='ticket-card-details'>
                        <div>
                            <div>
                                <span>START DATE</span>
                                <small>{sDate}</small>
                            </div>
                            <div>
                                <span>LOCATION</span>
                                <small>{props.event.City}</small>
                            </div>
                            {/* <div>
                            <span>Payment Status</span>
                            <small>{props.participant.PaymentStatus}</small>
                        </div> */}

                        </div>
                        <div>
                            <div>
                                <span>PRICE</span>
                                <small>{fee}</small>
                            </div>
                            {convenienceAmt > 0 && <div>
                                <span>OTHER CHARGE</span>
                                <small>{convenienceAmtTxt}</small>
                            </div>}

                            {/* <div>
                            <span>Payment Status</span>
                            <small>{props.participant.PaymentStatus}</small>
                        </div> */}

                        </div>
                        {/* <div>
                        <div>
                            <span>CATEGORY</span>
                            <small>{props.participant.CategoryName}</small>
                        </div>
                    </div> */}

                        <div style={{ paddingTop: '10px' }}>
                            <span style={{ fontSize: '0.7rem', color: '#aaa' }}>BOOKING ID : {props.participant.RegID}</span>
                        </div>
                    </div>

                    {/* <div className={props.participant.PaymentStatus === 'Completed' ? 'ticket-card-btn-div completed' : 'ticket-card-btn-div '}> */}
                    <div className={newPaymentStatus === 'Completed' ? 'ticket-card-btn-div completed' : 'ticket-card-btn-div '}>

                        {withdrawFlag && <div>
                            <button className='mybutton button5' style={{ background: '#fff', fontWeight: '600' }}
                                onClick={() => {
                                    setAction('WithdrawEntry');
                                    setShowPopup(true);
                                    // handleRefundEvent(props.participant)
                                }

                                }>Withdraw</button>
                        </div>}
                        {newPaymentStatus === 'Completed' ?
                            <div>
                                <img src="/img/paid1.png" width='100px' alt="" />
                                {/* <div className='paid-btn'>
                                    <h1>PAID</h1>
                                </div> */}
                            </div> :
                            <div>
                                <button className='mybutton button5' style={{ background: '#fff', fontWeight: '600' }}
                                    onClick={() => {
                                        handlePaymentClick();
                                    }}>{'PAY ' + totalAmtTxt}</button>

                                {/* {props.participant.RegType === 'Self' && <button className='mybutton button5' style={{ background: '#fff', fontWeight: '600' }}>{'PAY ' + totalAmtTxt}</button>}

                            {props.participant.RegType !== 'Self' && <button className='mybutton button5' style={{ background: '#fff', fontWeight: '600', pointerEvents: 'none' }}>
                                PENDING
                            </button>} */}
                            </div>
                        }
                        <div id="payuWidget"> </div>
                        {/* <PhonePePayment></PhonePePayment> */}
                        {/* <div>
                            <GooglePayButton
                                environment="TEST"
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: [
                                        {
                                            type: 'CARD',
                                            parameters: {
                                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                            },
                                            tokenizationSpecification: {
                                                type: 'PAYMENT_GATEWAY',
                                                parameters: {
                                                    gateway: 'example',
                                                    gatewayMerchantId: 'exampleGatewayMerchantId',
                                                },
                                            },
                                        },
                                    ],
                                    merchantInfo: {
                                        merchantId: '12345678901234567890',
                                        merchantName: 'Demo Merchant',
                                    },
                                    transactionInfo: {
                                        totalPriceStatus: 'FINAL',
                                        totalPriceLabel: 'Total',
                                        totalPrice: '100.00',
                                        currencyCode: 'INR',
                                        countryCode: 'IN',
                                    },
                                }}
                                onLoadPaymentData={paymentRequest => {
                                    console.log('load payment data', paymentRequest);
                                }}
                            />
                        </div> */}
                    </div>

                </div>

            </div >
        </div >
    )
}
