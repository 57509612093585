import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import UserProfileCard from './UserProfileCard';
import EDTournamentDetails from './EDTournamentDetails'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
// import $ from "jquery";
import '../css/EventRegistration.css'
// import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { useLocalStorage } from "../context/useLocalStorage";
import NewMember from './NewMember';
import SignIn from './SignIn.js';


export default function RegisteredProfile() {
    const { users, user } = useUserAuth();
    const eventDetails = useLocalStorage('EventDetails', null);
    const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    //const [userName, setUserName] = useState();
    // const [gender, setGender] = useState('Male');
    // const [userEmail, setUserEmail] = useState();

    // const [location, setLocation] = useState({
    //     pincode: '',
    //     city: '',
    //     state: '',
    //     district: ''
    // });
    // const [country, setCountry] = useState();
    // const [showError, setShowError] = useState(false);
    // const [participantID, setParticipantID] = useState();
    const [participantList, setParticipantList] = useState();
    // const [address, setAddress] = useState();
    // const [size, setSize] = useState();
    //const [identity, setIdentity] = useState();
    // const [companyName, setCompanyName] = useState(true);
    // const [hrContact, setHRContact] = useState(false);
    // const [companyAddress, setCompanyAddress] = useState(false);
    // const [collageName, setCollageName] = useState();
    // const [flag, setFlag] = useState('first');
    const [showLoading, setShowLoading] = useState(true);
    const [addNewFlag, setAddNewFlag] = useState(false);
    const selectedPlayer = '';
    const [signinFlag, setSignInFlag] = useState(false);


    useEffect(() => {
        console.log("user : ", user);
        console.log('userDetails : ', userDetails);
        if (user.isLoggedIn && userDetails !== null) {
            if (user.userInfo) {
                // setUserEmail(userDetails ? userDetails.Email : '');
            }
            console.log('userDetails', userDetails);
            userDetails && setParticipantList(userDetails[0].RegisteredUser);

            setSignInFlag(true);
        }
        else {
            setSignInFlag(false);
        }
    }, [user, userDetails])
    function regProfileToSecondSlide(e) {
        // e.preventDefault();
        // setShowUserBasicDetails(true);
        setAddNewFlag(true);
        // setFlag('second');

    }

    function setSignIn(flag, userData) {
        setSignInFlag(flag);
        setUserDetails(userData);
    }

    function addNewMember(flag, playercode) {
        console.log('in Refistere file : addNewMember', flag);
        setAddNewFlag(flag, playercode);
        // fetchData();


    }
    let newArray = users && users.current && users.current.phoneNumber && users.current.phoneNumber.replace('+', '').match(/^(91|)?(\d{3})(\d{3})(\d{4})$/)

    return (
        <>

            <div className="container-fluid">
                <div className="row no-gutters">

                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <br />
                        {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>}

                        {addNewFlag && <div id="regProfileNewParticipantDetails">
                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>NEW PARTICIPANT</h3>
                            <h1 className="reg-form-email" >{userDetails ? userDetails.Email : ''}</h1>
                            {/* <h2 className="reg-form-email" id="userContact">{users && users.current ? users.current.phoneNumber : ''}</h2> */}
                            <h2 className="reg-form-email" id="userContact">+{newArray && newArray.length >= 5 ? +newArray[1] + '-' + newArray[2] + '-' + newArray[3] + '-' + newArray[4] : ''}</h2>

                            <h5 className="reg-form-email male" id="userGender">{userDetails ? userDetails.Gender : ''}</h5>
                            <h6 className="reg-form-email" id="userLocation">{userDetails ? userDetails.City : ''}, {userDetails ? userDetails.State : ''}</h6>
                            <NewMember selectedPlayer={selectedPlayer} addNewMember={addNewMember} showBack={true}></NewMember>

                            <br /><br />

                        </div>}


                        {!addNewFlag && <div className="reg-participant-divs" id="regProfileFirstSlide"  >

                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>YOUR LIST</h3>

                            <div className="row no-gutters" id="divParticipant">

                                <div className="col-lg-4 col-md-6 col-sm-12" style={{
                                    padding: '0'
                                }}>
                                    <div style={{ padding: '10px' }}>
                                        < div className="event-registration-participant-card add-paticipant-card"
                                            onClick={regProfileToSecondSlide}>
                                            <span className="material-symbols-outlined">
                                                add
                                            </span>
                                            <h1>ADD NEW</h1>
                                        </div>
                                    </div>
                                </div>

                                {showLoading && <div className="col-lg-4 col-md-6 col-sm-12">
                                    <lottie-player src="https://lottie.host/35ed7cc5-900e-420b-95d1-cb90642020e7/UV7Rv7AbhO.json" background="transparent" speed="1" style={{ width: '100%', height: '100%' }} loop autoplay></lottie-player>
                                </div>}

                                {participantList && participantList.map((participant) => {
                                    return <UserProfileCard key={participant.id} participantDetails={participant} calledFrom="RegisteredProfile"></UserProfileCard>
                                })}

                            </div><br />
                            <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />

                        </div>}

                        {/* <div className="reg-participant-divs" id="regProfileSecondSlide">

                                    <NewMember selectedPlayer={selectedPlayer} addNewMember={addNewMember}></NewMember>

                                </div> */}

                        {/* </div> */}
                        {/* </div> */}

                    </div>
                    {console.log('eventDetails', eventDetails)}
                    {eventDetails[0] && <EDTournamentDetails eventDetails={eventDetails[0]} showRegistration={false} />}

                    <br />
                </div>
            </div>
            <br /><br /><br />

        </>
    )
}
