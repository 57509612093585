
import '../css/BeforeNavbar.css'
import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
// import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useLocalStorage } from "../context/useLocalStorage";
// import { useUserAuth } from '../context/UserAuthcontext';


export default function BeforeNavbar() {
    // const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    const userDetails = useLocalStorage('userProfile', null);

    // const { user } = useUserAuth();

    // const { state } = useLocation();
    // const { city } = state;

    const [city, setCity] = useState();
    // const [selectedRole, setSelectedRole] = useState("");
    useEffect(() => {
        // console.log(user);
        setCity(window.localStorage.getItem("userLocation") ? window.localStorage.getItem("userLocation").replaceAll('"', '') : 'All');
        // let role = window.localStorage.getItem("SelectedRole");
        // if (user.isLoggedIn) {
        //     if (user.userInfo) {
        //         // if (user.userInfo.)
        //         setSelectedRole(role[0].SelectedRole);
        //     }
        // } else {
        //     setSelectedRole('');
        // }
        // setSelectedRole(window.localStorage.getItem("SelectedRole") ? (window.localStorage.getItem("SelectedRole")[0] ? window.localStorage.getItem("SelectedRole")[0].SelectedRole : '') : '');
    }, [city])
    return (
        <div>
            {/* {console.log('in BeforeNavBar', city)} */}

            <div className="before-nav">

                <div className="icons">
                    <a style={{ fontsize: '0.9rem' }} href="https://www.facebook.com/TournamentPlanner/?ti=as"> <i
                        className="fab fa-facebook-f"></i> </a>
                    <a style={{ fontsize: '0.9rem' }} href="https://twitter.com/TPLIVE7/status/1144269372854288385?s=08"> <i
                        className="fab fa-twitter"></i> </a>
                    <a style={{ fontsize: '0.9rem' }} href="https://www.youtube.com/channel/UCNkSsrEoWHJ_eWvLSQiGNug"> <i
                        className="fab fa-youtube"></i> </a>
                    <a style={{ fontsize: '0.9rem' }} href="https://www.instagram.com/tplive.in/"> <i className="fab fa-instagram"></i> </a>
                </div>

                <div className="before-nav-content">
                    {/* <Link to="/Event" style={{ margin: '0 5px' }}>
                        <button type="button" className="mybutton buttonTransparent" name="button">Corporate Events</button>
                    </Link>

                    <Link to="/Event" style={{ margin: '0 5px' }}>
                        <button type="button" className="mybutton buttonTransparent" name="button">List Your Events</button>
                    </Link> */}

                    <div className="before-nav-icons">
                        {/* 
                        <a href="/">
                            <span style={{
                                position: 'absolute',
                                padding: '0',
                                marginLeft: '20px'
                            }} className="material-symbols-outlined">
                                segment
                            </span>
                            <span className="material-symbols-outlined">
                                favorite
                            </span>
                        </a> */}
                        {/* //Code chaneg for Prod */}

                        {/* <Link
                            to="/Event">
                            <span className="material-symbols-outlined">
                                search
                            </span>
                        </Link>
 */}


                        {/* //Code chaneg for Prod */}

                        {/* <Link to="/Profile" style={{ flexDirection: 'column' }}>
                            <span className="material-symbols-outlined" style={{ position: 'relative', top: '3px' }}>
                                person
                            </span>
                            {userDetails && userDetails.SelectedRole && userDetails.SelectedRole !== 'PARTICIPANT' &&
                                <span style={{ fontSize: '0.7rem', fontWeight: '500' }}>{userDetails.SelectedRole}</span>}
                        </Link> */}

                        {/* <a href="JavaScript:askforNotification();" className="nav-location"> */}
                        {/* <a href="/" className="nav-location" style={{ position: 'relative' }}>
                            <span className="material-symbols-outlined">
                                notifications
                            </span>
                            <div className='notification-dot' style={{ right: '9px' }}></div>
                        </a> */}

                        <Link to="/Location" className="nav-location">
                            <span className="material-symbols-outlined">
                                add_location_alt
                            </span>

                            <small id="location">{window.localStorage.getItem("userLocation") ? window.localStorage.getItem("userLocation").replaceAll('"', '') : 'All'}</small>

                        </Link>

                    </div>

                </div>
            </div>

        </div >
    )
}
