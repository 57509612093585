import React, { useEffect, useState } from 'react'
import { useRazorpay } from '../context/useRozarPay';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";

export default function PaymentSection(props) {
    const [paymentSectionFlag, setPaymentSectionFlag] = useState(false);

    const { user } = useUserAuth();
    const userDetails = useLocalStorage('userProfile', null);

    const [paymentSummary, setPaymentSummary] = useState({
        totalFee: 0,
        categoryCount: 0,
        convenienceCharge: 0,
        convenienceChargeTxt: '0',
        totalPayment: 0,
        totalPaymentTxt: '0',
        regID: [],

    })
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const { makePayment, paymentResponse } = useRazorpay();
    // var options = {
    //     year: '2-digit',
    //     // year: 'numeric',
    //     month: 'short',
    //     day: 'numeric'
    // };
    useEffect(() => {
        // console.log('props', props);
        let tAmt = 0;
        let tEvents = 0;
        let convenienceAmt = 0;
        let idList = [];
        props.Category.forEach(element => {
            tAmt = tAmt + Number(element.Fees);
            tEvents = tEvents + 1;
            idList.push(element.RegID);
        });
        if (props.ConvenienceCharge && props.ConvenienceCharge !== '' && props.ConvenienceCharge !== '0') {
            convenienceAmt = Math.round(tAmt * Number(props.ConvenienceCharge) / 100);
        }
        let totalPayment = Number(tAmt) + Number(convenienceAmt);
        setPaymentSummary({
            totalFee: Number(tAmt),
            categoryCount: tEvents,
            convenienceCharge: Number(convenienceAmt),
            convenienceChargeTxt: Number(convenienceAmt).toLocaleString('en-IN', curFormat),
            totalPayment: Number(totalPayment),
            totalPaymentTxt: Number(totalPayment).toLocaleString('en-IN', curFormat),
            regID: idList
        })
    }, [])
    const handlePayment = () => {

        if (!(user && user.isLoggedIn && user.userInfo)) {
            props.setIsPayClicked(true);
        } else {
            let orderId = 'O_' + props.EventCode + '_' + props.PlayerID + '_' + new Date().getTime();

            makePayment(orderId,
                Number(paymentSummary.totalPayment),
                userDetails.UserName, 'Payment for Registration',

                userDetails && userDetails.UserEmail,
                user.phoneNumber,
                paymentSummary.regID,
                // 'anita tripathi', 'Payment for Registration',
                // 'anitatripathi@gmail.com',
            ).then(async (e) => {
                console.log(e)
            })
        }


    };


    useEffect(() => {
        console.log(paymentResponse)
    }, [paymentResponse])

    return (
        <div className={paymentSectionFlag ? 'payment-grid-div open' : 'payment-grid-div'} >
            <div className='payment-grid-div-arrow'>
                <span className="material-symbols-outlined">
                    expand_less
                </span>
            </div>
            <div className='payment-grid-div-second'>

                {props.Category && props.Category.map((category) => {
                    let amt = Number(category.Fees).toLocaleString('en-IN', curFormat)
                    return <div key={category.CategoryName}>
                        <small>{category.CategoryName}</small>
                        <span>{amt}</span>
                    </div>
                })}
                {/* <div>
                    <small>Boy's Singles Under 19</small>
                    <span>₹1,000</span>
                </div>
                <div>
                    <small>XD</small>
                    <span>₹3,690</span>
                </div> */}
                {paymentSummary.convenienceCharge > 0 &&
                    <div>
                        <small style={{ color: '#fff' }}>{'Convenience Charges (' + props.ConvenienceCharge + '%)'}</small>
                        <span style={{ color: '#fff' }}>{paymentSummary.convenienceChargeTxt}</span>
                    </div>
                }

                {/* <div>
                    <small style={{ color: '#fff' }}>Convenience Charges (5%)</small>
                    <span style={{ color: '#fff' }}>₹250</span>
                </div> */}
            </div>
            <div className='payment-grid-div-first'>
                {props.Category.length === 0 ? <div>
                    <img src="./img/paid1.png" width='100px' alt="" />
                </div> : <div>
                    <button className='mybutton button5' style={{ fontWeight: '600' }}
                        onClick={() => handlePayment()}>{'Pay : ₹' + paymentSummary.totalPayment}</button>
                </div>}
                <div onClick={() => setPaymentSectionFlag(!paymentSectionFlag)}>
                    <h1>{paymentSummary.totalPaymentTxt}</h1>
                    <h2>{paymentSummary.categoryCount} Category</h2>
                </div>
            </div>
        </div>
    )
}
