import React, { useEffect, useState } from 'react';
import '../css/NewEventCard.css'
import { useNavigate } from 'react-router-dom';

import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";


export default function NewEventCard(props) {
    const navigate = useNavigate();
    const { users, user, logout } = useUserAuth();
    const [userDetails] = useLocalStorage('userProfile', null);
    // console.log('props : ', props)
    const [url, setURL] = useState('');
    const [eventStatus, setEventStatus] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [eventCode, setEventCode] = useState('BD');
    const [eventStatusDes, setEventStatusDes] = useState('');

    useEffect(() => {


        setEventStatus(props.event.EventStatus);
        setRegistrationStatus(props.event.RegistrationOpenFlag ? props.event.RegistrationOpenFlag.toUpperCase() : '');
        setPaymentStatus(props.event.OnlinePaymentModeFlag ? props.event.OnlinePaymentModeFlag.toUpperCase() : '');
        if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'CLOSED')
            setEventStatusDes('Event is Closed')
        else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'CANCELLED')
            setEventStatusDes('Event is Cancelled')
        else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'INACTIVE')
            setEventStatusDes('Event is in-active')
        else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'ONHOLD')
            setEventStatusDes('Event is Onhold')
        else if (props.event.EventMode && props.event.EventMode.toUpperCase() === 'FIXER')
            setEventStatusDes('Draw create')
        else if (props.event.RegistrationOpenFlag && props.event.RegistrationOpenFlag.toUpperCase() === 'ON')
            setEventStatusDes('Registration is open')
        else
            setEventStatusDes('Registration is open')
        switch (props.event.SportName.toUpperCase()) {
            case 'BADMINTON':
                setEventCode('BD')
                setURL(
                    props.event.EventLogo ? props.event.EventLogo
                        : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Fbadminton.webp?alt=media&token=e6aad2a0-7715-4714-991b-82042fd12b41'
                );

                break;
            case 'CARROM':
                setEventCode('CR')
                setURL(
                    props.event.EventLogo ? props.event.EventLogo
                        : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Fcarrom.webp?alt=media&token=e7d92e92-bfe1-4ed9-9064-62d8e6a7dda6'
                );
                break;
            case 'CHESS':
                setEventCode('CH')
                setURL(
                    props.event.EventLogo ? props.event.EventLogo
                        : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Fchess.webp?alt=media&token=9d10730d-3a38-435f-9bb7-c89aa2334b2c'
                );

                break;
            case 'SQUASH':
                setEventCode('SQ')
                setURL(
                    props.event.EventLogo ? props.event.EventLogo
                        : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Fsquash.webp?alt=media&token=89e9d559-8cab-4504-a123-26ee966e88b0'
                );

                break;
            case 'TABLE TENNIS':
                setEventCode('TT')
                setURL(
                    props.event.EventLogo ? props.event.EventLogo
                        : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Ftabletennis.webp?alt=media&token=8f1dd9cb-95c8-4b0e-b30d-aedbd8386984'
                );

                break;
            case 'TENNIS':
                setEventCode('TN')
                setURL(props.event.EventLogo ? props.event.EventLogo
                    : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Ftennis.webp?alt=media&token=72fdf3fc-3bb6-4994-8b84-32780c57abec'
                );

                break;
            case 'CRICKET':
                setEventCode('CK')
                setURL(props.event.EventLogo ? props.event.EventLogo
                    : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2FCK.png?alt=media&token=8d11595b-1b8d-4538-883a-9a8051b76ada')


                break;
            default:
                setEventCode('BD')
                setURL(props.event.EventLogo ? props.event.EventLogo
                    : 'https://firebasestorage.googleapis.com/v0/b/tplive-prod.appspot.com/o/img%2Fevent%2Fbadminton.webp?alt=media&token=e6aad2a0-7715-4714-991b-82042fd12b41'
                );
        };

    }, [])
    function selectEvent() {
        // var para1 = {};
        // console.log('in selectEvent : userDetails.SelectedRole', userDetails.SelectedRole);
        window.localStorage.setItem("EventID", JSON.stringify(props.event.Eventid));
        window.localStorage.setItem("EventDetails", JSON.stringify(props.event));
        if (userDetails && userDetails.SelectedRole !== 'PARTICIPANT') {
            navigate("/EditEvent", {
                state: {
                    eventID: props.event.Eventid,

                }
            });

        }
        else {
            //Code chaneg for Prod need to check
            // navigate("/EventDetails", {
            //     state: {
            //         eventID: props.event.Eventid,
            //         eventDetails: props.event,
            //         entryCount: props.event.EntryCount
            //     }
            // });
            console.log('props.event.EventCode', props.event.EventCode)
            if (props.event.EventCode === 'TP_TT_0001') {
                // window.location.href = "https://tournamentplanner.in/screens/TPLive_TournamentDetails.aspx?SCode=" + eventCode + "&TCode=" + props.event.EventCode;
                navigate("/EventDetails", {
                    state: {
                        eventID: props.event.Eventid,
                        eventDetails: props.event,
                        entryCount: props.event.EntryCount
                    }
                });
            } else {
                window.location.href = "https://tournamentplanner.in/screens/TPLive_TournamentDetails.aspx?SCode=" + eventCode + "&TCode=" + props.event.EventCode;

            }

        }
    }
    async function eventStatusChanged() {
        console.log('in eventStatusChanged')
        var para1 = {};
        let leventStatus = '';
        if (eventStatus === 'Active') {
            leventStatus = 'Inactive';
        }
        else {
            leventStatus = 'Active';
        }

        para1 = {
            EventID: props.event.Eventid,
            EventStatus: leventStatus,
        };
        // console.log(para1);
        const ret = await httpsCallable(functions, "updateEventDetails_EventStatus");
        ret(para1).then(async (result) => {
            setEventStatus(leventStatus)

        })
    }
    async function registrationStatusChanged() {
        console.log('registrationStatusChanged');
        let lregistrationStatus = '';
        if (registrationStatus === 'ON') {
            lregistrationStatus = 'OFF';
        }
        else {
            lregistrationStatus = 'ON';
        }
        var para1 = {};
        para1 = {
            EventID: props.event.Eventid,
            RegistrationOpenFlag: lregistrationStatus,
        };
        // console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_RegistrationOn");
        ret(para1).then(async (result) => {
            setRegistrationStatus(lregistrationStatus);
        })
    }
    async function paymentStatusChanged() {
        console.log('paymentStatusChanged');
        let lpaymentStatus = '';
        if (paymentStatus === 'On') {
            lpaymentStatus = 'Off';
        }
        else {
            lpaymentStatus = 'On';
        }

        var para1 = {};
        para1 = {
            EventID: props.event.Eventid,
            OnlinePaymentModeFlag: lpaymentStatus,
        };
        // console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_OnlinePaymentMode");
        ret(para1).then(async (result) => {
            setPaymentStatus(lpaymentStatus);
        })
    }
    // console.log('props.event', props.event, ':: RegistrationOpenFlag :: ', registrationStatus);
    return (
        <>
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='new-event-card'  >
                    <div className={((userDetails && userDetails.SelectedRole === 'PARTICIPANT') || !userDetails) ? 'new-event-card-onclick-full-div' : 'new-event-card-onclick-full-div dont-show'} onClick={selectEvent}></div>
                    {userDetails && userDetails.SelectedRole !== 'PARTICIPANT' && <div className='new-event-card-edit' onClick={selectEvent}>
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                    </div>}
                    <div>
                        <img src={url} alt="" />
                    </div>
                    <div className='new-event-card-details'>
                        <div className='new-event-card-details-name'>
                            {/* {console.log('userDetails, userDetails', userDetails)} */}
                            {userDetails && userDetails.SelectedRole !== 'PARTICIPANT' && <h1>{props.event.EventCode}</h1>}
                            <h2>{props.event.EventName}</h2>
                            <h3>{props.event.OrganizationName}</h3>
                            <h3>{eventStatusDes}</h3>

                            <br></br>

                            {userDetails && userDetails.SelectedRole !== 'PARTICIPANT' &&
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: '1rem', color: '#aaa' }}>Event Status</span>
                                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                            <span className={eventStatus === 'Active' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>

                                            <div className={eventStatus === 'Active' ? 'switch-button on' : 'switch-button off'}
                                                onClick={eventStatusChanged} >
                                                <div className="switch-button-inner"></div>
                                            </div>
                                            <span className={eventStatus === 'Active' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                        </div>
                                    </div>

                                    <hr style={{ margin: '8px 0' }} />
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: '1rem', color: '#aaa' }}>Registration Status</span>
                                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>


                                            <span className={registrationStatus === 'ON' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>

                                            <div className={registrationStatus === 'ON' ? 'switch-button on' : 'switch-button off'}
                                                onClick={registrationStatusChanged}>
                                                <div className="switch-button-inner"></div>
                                            </div>

                                            <span className={registrationStatus === 'ON' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                        </div>
                                    </div>
                                    <hr style={{ margin: '8px 0' }} />
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: '1rem', color: '#aaa' }}>Payment Status</span>
                                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                            <span className={paymentStatus === 'ON' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>
                                            <div className={paymentStatus === 'ON' ? 'switch-button on' : 'switch-button off'}
                                                onClick={paymentStatusChanged}>
                                                <div className="switch-button-inner"></div>
                                            </div>

                                            <span className={paymentStatus === 'ON' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                        </div>
                                    </div>
                                </>}
                        </div>

                        <div className='new-event-card-details-date'>
                            <div>
                                <h4>{props.event.City}</h4>
                                <h5>Location</h5>
                            </div>
                            <div>
                                <h4>{props.event.EventSDate}</h4>
                                <h5>Starting Date</h5>
                            </div>
                            <div>
                                <h4> {props.event.Fees === '' ? '₹ 0' : props.event.Fees}</h4>
                                <h5>Entry Fee</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
