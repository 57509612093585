import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import Loading from './Loading.js';

export default function EventDetailsLink() {
    const { eventid } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [entryCount, setEntryCount] = useState(0);
    // const [entryCompletedCount, setEntryCompletedCount] = useState(0);
    // const [eventDetails, setEventDetails] = useState(null);

    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    useEffect(() => {
        // console.log('eventid', eventid);
        async function fetchData() {
            setLoading(true);
            let para = {
                EventID: eventid,

            };
            let cnt = 0;
            // console.log('para', para)
            const eventEntryCount = httpsCallable(functions, "getEventsEntryCount");
            await eventEntryCount(para)
                .then((resultCnt) => {
                    let dataCnt = resultCnt.data;

                    // console.log('dataCnt : ', dataCnt);
                    dataCnt.forEach(elementCnt => {
                        cnt = cnt + Number(elementCnt.EntryCount);
                        // setEntryCount(Number(entryCount) + Number(elementCnt.EntryCount));
                        // setEntryCompletedCount(Number(entryCompletedCount) + Number(elementCnt.CompletedCount));

                    })
                    // console.log('cnt ', cnt, 'entryCount ', entryCount)
                });
            const eventSummaryBySports = httpsCallable(functions, "getEventDetails");

            await eventSummaryBySports(para)
                .then((result) => {
                    // Read result of the Cloud Function.
                    // console.log('result.data', result.data);
                    // let data = result.data;
                    var refdate = "";
                    var today = new Date();
                    var eventSDate = '';
                    var eventEDate = '';
                    if (result.data) {
                        if (result.data.EventStartDate) {
                            refdate = new Date(result.data.EventStartDate._seconds * 1000);
                            // element.EventStartDate = refdate.toLocaleDateString("en-IN", options);
                            eventSDate = refdate.toLocaleDateString("en-IN", options);
                        }
                        else {
                            eventSDate = "";
                        }
                        var eDate = new Date(result.data.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000);
                        // element.EventEndDate = eDate.toLocaleDateString("en-IN", options);
                        eventEDate = eDate.toLocaleDateString("en-IN", options);
                        if (refdate <= today && eDate >= today && result.data.EventStatus.toUpperCase() === 'ACTIVE') {
                            result.data.isLive = true;
                        } else {
                            result.data.isLive = false;
                        }

                        // element.MinimumFee = element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "";
                        result.data.Fees = result.data.MinimumFee ? (Number(result.data.MinimumFee).toLocaleString('en-IN', curFormat)) : "";

                        result.data.EventEDate = eventEDate;

                        result.data.EventSDate = eventSDate;
                    }
                    // setEventDetails(result.data);
                    //const sanitizedMessage = data.text;
                    // console.log('entryCount', entryCount);
                    navigate("/EventDetails", { state: { eventID: result.data.Eventid, eventDetails: result.data, entryCount: cnt } });

                });


            setLoading(false);

        }
        fetchData();
    }, [eventid])
    return (
        <div>
            {loading && <Loading></Loading>}
        </div>
    )
}
