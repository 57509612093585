import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import EventDetailsMenu from './EventDetailsMenu';
import EDTournamentDetails from './EDTournamentDetails';
// import CategoryCartItem from './CategoryCartItem';
import Loading from './Loading.js';
import '../css/EventRegistration.css'
import '../css/PlayerParticipation.css'
import EntryTicket from './EntryTicket.js';
import PaymentSection from './PaymentSection.js';
import SignIn from './SignIn.js';
import { useUserAuth } from '../context/UserAuthcontext.js';
import { useLocalStorage } from '../context/useLocalStorage.js';
export default function PlayerParticipation() {
    const { state } = useLocation();
    const { user } = useUserAuth();
    const [isPayClicked, setIsPayClicked] = useState(false);
    const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    const [loginFlagForWithdraw, setLoginFlagForWithdraw] = useState(false);
    const { eventDetails, entryCount, playerID, uniqueParticipantDetails, participantDetails, participantCount, playerUserID } = state;
    const [loading, setLoading] = useState(false);
    const [objPlayerParticipant, setObjPlayerParticipant] = useState({
        playerParticipantDetails: [],
        playerParticipantCount: 0,
        showFlag: false
    });
    const [signinFlag, setSigninFlag] = useState(true);
    const [paymentSection, setPaymentSection] = useState({
        flag: false,
        pendingCategory: []
    });
    useEffect(() => {
        // console.log(user)
        // console.log('SigninFlag', signinFlag)
        setIsPayClicked(false);
        // if (!(user && user.isLoggedIn && user.userInfo)) {
        //     setSigninFlag(false);
        // }
        // console.log(eventDetails);
        var para1 = {};

        async function fetchData() {
            setLoading(true);
            para1 = {
                EventID: eventDetails.Eventid,
                PlayerID: playerID

            };
            // console.log(para1);
            const ret1 = httpsCallable(functions, "getAllRegisteredEventListByPlayerCode");
            ret1(para1).then(async (result) => {
                // console.log('result', result);
                setObjPlayerParticipant({
                    playerParticipantDetails: result.data.entryDetails,
                    playerParticipantCount: result.data.entryDetails.length,
                    showFlag: true
                });
                // let pending = result.data.entryDetails.filter(e => e.PaymentStatus === 'Pending' && e.RegType === 'Self')
                let pending = result.data.entryDetails.filter(e => e.PaymentStatus === 'Pending')

                // console.log('pending', pending);
                setPaymentSection({
                    flag: pending.length <= 1 ? false : true,
                    pendingCategory: pending,
                })
                setLoading(false);
            });

        }
        fetchData();
    }, []);
    function setSignIn(flag, userData) {
        // console.log(flag)
        setSigninFlag(flag);
        setUserDetails(userData);
    }

    function setLoginFlag(flag) {
        // console.log('in setLoginFlag', flag)
        // setLoginFlagForWithdraw(true)
        setSigninFlag(flag);
    }
    // console.log('objPlayerParticipant.playerParticipantDetails', objPlayerParticipant.playerParticipantDetails);
    // console.log('signinFlag : ', signinFlag, ' isPayClicked :: ', isPayClicked);
    return (
        <>
            <div className="container-fluid">

                <div className="row no-gutters">
                    <div className="col-lg-8 col-md-8 col-sm-12">

                        {eventDetails && <EventDetailsMenu calledFrom='Participant'
                            eventID={eventDetails.Eventid}
                            eventDetails={eventDetails}
                            entryCount={entryCount}
                            uniqueParticipantDetails={uniqueParticipantDetails}
                            participantDetails={participantDetails}
                            participantCount={participantCount}
                        />}
                        {/* {console.log('signinFlag ::', signinFlag, ':isPayClicked ::', isPayClicked)} */}
                        {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>}

                        {/* {loginFlagForWithdraw && <SignIn setSignIn={setSignIn}></SignIn>} */}

                        {/* {!signinFlag && isPayClicked && <SignIn setSignIn={setSignIn}></SignIn>} */}
                        {/* {<PaymentSection Category={objPlayerParticipant.playerParticipantDetails}
                            ConvenienceCharge={eventDetails.ConvenienceCharge}></PaymentSection>} */}
                        {paymentSection.flag && <PaymentSection Category={paymentSection.pendingCategory}
                            ConvenienceCharge={eventDetails.ConvenienceCharge}
                            PlayerID={participantDetails.PlayerID}
                            EventCode={eventDetails.EventCode} setIsPayClicked={setIsPayClicked}></PaymentSection>
                        }
                        {/* <div className={paymentSectionFlag ? 'payment-grid-div open' : 'payment-grid-div'} >
                            <div className='payment-grid-div-arrow'>
                                <span className="material-symbols-outlined">
                                    expand_less
                                </span>
                            </div>
                            <div className='payment-grid-div-second'>
                                <div>
                                    <small>Boy's Singles Under 19</small>
                                    <span>₹1,000</span>
                                </div>
                                <div>
                                    <small>XD</small>
                                    <span>₹3,690</span>
                                </div>
                                <div>
                                    <small style={{ color: '#fff' }}>Convenience Charges (5%)</small>
                                    <span style={{ color: '#fff' }}>₹250</span>
                                </div>
                            </div>
                            <div className='payment-grid-div-first'>
                                <div>
                                    <button className='mybutton button5' style={{ fontWeight: '600' }}>Pay Now</button>
                                </div>
                                <div onClick={() => setpaymentSectionFlag(!paymentSectionFlag)}>
                                    <h1>₹2,000</h1>
                                    <h2>3 Category</h2>
                                </div>
                            </div>
                        </div> */}

                        {loading && <Loading></Loading>}

                        <br></br>
                        <div className="row no-gutters" id="divRegEvent">

                            {/* 
                            {objPlayerParticipant.showFlag && objPlayerParticipant.playerParticipantDetails.map((events) => {
                                return <CategoryCartItem key={events.CategoryName} eventDetails={events} ></CategoryCartItem>
                            })} */}

                            {objPlayerParticipant.showFlag && objPlayerParticipant.playerParticipantDetails.map((events) => {
                                return <EntryTicket key={events.CategoryName} event={eventDetails} participant={events} setIsPayClicked={setIsPayClicked} showQR={false}
                                    setLoginFlag={setLoginFlag}
                                ></EntryTicket>
                            })}
                            {/* <span> * marked event is registered by Partner</span> */}

                        </div>

                    </div>

                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}
                    {/* {eventDetails && <EDAboutEvent eventDetails={eventDetails} />} */}
                </div>
            </div >
            {/* <div className="container-fluid">
                <div className="row no-gutters">
                    {eventDetails && <EventDetailsMenu eventDetails={eventDetails}
                        calledFrom='Participant'
                        participantCount={setUniqueParticipantDetails.length}
                        participantDetails={uniqueParticipantDetails}
                        isLoading={loading} />}
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}
                </div>
            </div> */}
        </>

    )

}
