import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
// import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import Loading from './Loading.js';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useRazorpay } from '../context/useRozarPay';
import { useNavigate } from 'react-router-dom';
import '../css/Ticket.css';
import EntryTicket from './EntryTicket';
// import QRCodeComponent from './QRCodeComponent';
export default function ManageParticipant() {
    const { user, logout } = useUserAuth();
    const navigate = useNavigate();
    const [userDetails] = useLocalStorage('userProfile', null);
    const [loading, setLoading] = useState(false);
    const [objPlayerParticipant, setObjPlayerParticipant] = useState(null);
    const [withdraw, setWithdraw] = useState(false);
    const [payment, setPayment] = useState(false);
    const { makePayment, paymentResponse, handleRefund } = useRazorpay();
    const [searchKey, setSearchKey] = useState('');
    const [searchedEntry, setSearchedEntry] = useState([]);
    useEffect(() => {
        // console.log(eventDetails);
        var para1 = {};
        async function fetchData() {
            console.log('userDetails', userDetails);
            setLoading(true);
            para1 = {
                UserID: userDetails.id,
            };
            console.log(para1);
            const ret1 = httpsCallable(functions, "getAllRegisteredEventListByUserID");
            ret1(para1).then(async (result) => {
                let entryDet = [];
                let eventDet = [];
                result.data.eventDetails.forEach(element => {
                    if (element.EventStatus.toUpperCase() === 'OPEN' || element.EventStatus.toUpperCase() === 'UPCOMING')
                        eventDet.push(element)

                });
                result.data.entryDetails.forEach(element => {
                    if (eventDet.filter(e => e.EventID === element.EventID)) {
                        entryDet.push({
                            ...element,
                            searchKey: element.CategoryName + element.ParticipantName + (element.PartnerPlayerName ? element.PartnerPlayerName : '') + element.PaymentStatus + element.EventType
                        })
                    }

                });
                // console.log('entryDet 1', entryDet)
                setObjPlayerParticipant({
                    eventDetails: result.data.eventDetails,
                    entryDetails: entryDet
                });
                console.log('objPlayerParticipant', objPlayerParticipant);
                // console.log('setObjPlayerParticipant : ', objPlayerParticipant);
                setSearchedEntry(entryDet);
                // let para2 = {
                //     emails: 'atulmani@gmail.com;anitatripathi@gmail.com',
                //     subject: 'test email',
                //     body: 'test email from TPLive',
                // };
                // console.log(para2);
                // const ret2 = httpsCallable(functions, "sendMailApi");
                // ret2(para2).then(async (result) => {

                // });
                setLoading(false);
            });

        }
        fetchData();


    }, []);

    const handleRefundEvent = async (obj) => {
        //uppdate the registration status as withdrawn

        WithdrawEntry(obj);

        if (obj.PaymentStatus.toUpperCase() === 'COMPLETED') {
            handleRefund(obj, 'Entry Withdraw ')
        }
    };


    async function ConfirmPayment(obj, amount, transactionID, orderID) {
        var catDel = [];
        catDel.push(obj.CategoryName);
        var para1 = {};
        para1 = {
            EventID: obj.EventID,
            PlayerID: obj.ParticipantID,
            CategoryList: catDel,
            paymentStatus: 'Completed',
            paymentAmount: amount,
            transactionID: transactionID,
            orderID: orderID,
        };

        const ret1 = await httpsCallable(functions, "updatePaymentStatus");
        ret1(para1).then(async (result) => {
            // props.refreshParent();
        })

    }
    async function WithdrawEntry(participantDetails) {
        var catDel = [];
        catDel.push(participantDetails.CategoryName);
        var para1 = {};
        para1 = {
            EventID: participantDetails.EventID,
            PlayerID: participantDetails.ParticipantID,
            DeleteCategoryList: [participantDetails.CategoryName],
        };

        const ret1 = await httpsCallable(functions, "withdrawRegistration");
        ret1(para1).then(async (result) => {

        })

    }
    function searchParticipation() {
        if (searchKey !== '') {
            var newArray = objPlayerParticipant.entryDetails.filter(function (el) {
                return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
            }
            );
            // console.log(newArray);
            setSearchedEntry(newArray);
            // setFilterEventStatus('');
        }
    }
    function resetParam() {
        navigate(-1);
    }
    let eventDet = null;

    return (
        <div className='container-fluid'>

            <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {<span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> Manage Participant </span>



            </div>

            <div className="row no-gutters">
                {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                    <QRCodeComponent value='100'></QRCodeComponent>
                </div> */}
                {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className='ticket-card'>
                        <div className='ticket-card-heading'>
                            <div style={{ paddingRight: '20px' }}>
                                <h1>CNS Badminton Championship</h1>
                                <div>
                                    <span className="material-symbols-outlined">
                                        person
                                    </span>
                                    <h2>15th Cross Road, Banglore, Karnataka, India</h2>
                                </div>
                            </div>
                            <div style={{ paddingLeft: '20px' }}>
                                <img src="./img/qr.jpg" alt="" />
                            </div>
                        </div>
                        <div className='ticket-card-divider'>
                            <div></div>
                            <h3>MORE DETAILS</h3>
                            <div></div>
                        </div>
                        <div className='ticket-card-details'>
                            <div>
                                <div>
                                    <span>DATE</span>
                                    <small>09 Aug '23</small>
                                </div>
                                <div>
                                    <span>SCREEN</span>
                                    <small>Audi 06</small>
                                </div>
                                <div>
                                    <span>FEES</span>
                                    <small>₹ 600</small>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>CATEGORY</span>
                                    <small>BS U15, BD U17</small>
                                </div>
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                                <span style={{ fontSize: '0.8rem', color: '#aaa' }}>BOOKING ID : <strong style={{ color: '#222' }}>BMCB00000536789</strong></span>
                            </div>
                        </div>

                        <div className='ticket-card-btn-div'>
                            <div>
                                <h5>If you want change your partner or see more details click here</h5>
                            </div>
                            <div>
                                <button className='mybutton button5' style={{ background: '#fff', color: '#348DCB' }}>DETAILS</button>
                            </div>
                        </div>

                    </div>
                </div> */}

                {loading && <Loading></Loading>}
                {searchedEntry.length === 0 &&
                    // {true &&

                    <div style={{ alignContent: "center" }}>
                        <strong >You have no registration !!</strong>

                    </div>

                }
                {objPlayerParticipant && objPlayerParticipant.entryDetails && objPlayerParticipant.entryDetails.length > 5 &&
                    <><br className='small'></br>
                        <div className='event-search-div'>
                            <div>
                                <input type="text" id="userName" placeholder='e.g. event name or player name or category name etc' required
                                    onChange={(e) => {
                                        setSearchKey(e.target.value)
                                        searchParticipation()

                                    }} value={searchKey} />
                                <button className="mybutton button5" onClick={() => {
                                    searchParticipation();

                                }}>
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {searchedEntry.map((participant) => {

                    // console.log('participant', participant);
                    eventDet = objPlayerParticipant.eventDetails.find(e => e.EventID === participant.EventID)
                    return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>
                    // return <div className="col-lg-4 col-md-6 col-sm-12">
                    //     <div style={{ padding: '10px' }}>
                    //         {/* {console.log(props)} */}

                    //         <div className={participant.PaymentStatus.toUpperCase() === 'PENDING' ?
                    //             "reg-category-card active payment-pending" : "reg-category-card active payment-completed"} style={{ display: 'block' }}>

                    //             <div className="display-flex-div">
                    //                 <div className="category-details">
                    //                     <h1 style={{ color: '#666', fontSize: '0.8rem', fontWeight: 'normal' }}>{eventDet.EventName}</h1>

                    //                     <h1 width='100%'>{participant.ParticipantName}
                    //                         {participant.PartnerPlayerID !== '' ? '-' + participant.PartnerPlayerName : ''
                    //                         }
                    //                     </h1>

                    //                     {/* <h1>{participant.ParticipantName}- {participant.CategoryName}</h1> */}

                    //                     {participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'SINGLE' ? <div className="category-icons">
                    //                         <span className="material-symbols-outlined female">
                    //                             woman
                    //                         </span>
                    //                     </div> :
                    //                         participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                             <span className="material-symbols-outlined female">
                    //                                 woman
                    //                             </span>
                    //                             <span className="material-symbols-outlined female">
                    //                                 woman
                    //                             </span>
                    //                         </div> :
                    //                             participant.Gender.toUpperCase() === 'MIXED' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                                 <span className="material-symbols-outlined female">
                    //                                     woman
                    //                                 </span>
                    //                                 <span className="material-symbols-outlined male">
                    //                                     man
                    //                                 </span>
                    //                             </div> :
                    //                                 participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'SINGLE' ? <div className="category-icons">
                    //                                     <span className="material-symbols-outlined male">
                    //                                         man
                    //                                     </span>
                    //                                 </div> :
                    //                                     participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                                         <span className="material-symbols-outlined male">
                    //                                             man
                    //                                         </span>
                    //                                         <span className="material-symbols-outlined male">
                    //                                             man
                    //                                         </span>
                    //                                     </div> :
                    //                                         participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'TEAM' ? <div className="category-icons">
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                         </div> :
                    //                                             participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'TEAM' ? <div className="category-icons">
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                             </div> :
                    //                                                 <div className="category-icons">
                    //                                                     <span className="material-symbols-outlined female">
                    //                                                         woman
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined female">
                    //                                                         woman
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined male">
                    //                                                         man
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined male">
                    //                                                         man
                    //                                                     </span>
                    //                                                 </div>
                    //                     }

                    //                     {<h3>
                    //                         <strong>{participant.CategoryName} </strong>
                    //                         {participant.PartnerPlayerID !== '' && participant.RegistrationType === 'Partner' ? ' (registered by Partner)' : ''}
                    //                     </h3>}
                    //                 </div>

                    //                 <div className="category-fees">
                    //                     <h2 style={{ position: 'relative', top: '5px' }}><span>₹ </span>
                    //                         <span>{participant.Fees}</span>
                    //                     </h2>

                    //                     {withdraw && <button className='mybutton button5' onClick={(e) => {
                    //                         if (window.confirm('Are you sure you wish to withdraw for "'
                    //                             + participant.CategoryName + '" for Event : ' + eventDet.EventName))
                    //                             console.log('deleted : ', participant.TransactionID);
                    //                         if (participant.TransactionID !== null && participant.TransactionID !== undefined && participant.TransactionID !== '')
                    //                             handleRefund(participant);
                    //                     }
                    //                     }>Withdraw</button>}

                    //                     {payment && <button className='mybutton button5' onClick={(e) => {
                    //                         handlePayment(participant, eventDet);
                    //                     }
                    //                     }>Pay Now</button>}

                    //                 </div>

                    //             </div>
                    //             {participant.PaymentStatus.toUpperCase() === 'PENDING' &&
                    //                 <div className="payment-status pending">
                    //                     <h1>Payment Pending</h1>
                    //                 </div>
                    //             }
                    //             {participant.PaymentStatus.toUpperCase() === 'COMPLETED' &&
                    //                 <div className="payment-status completed">
                    //                     <h1>Payment Completed</h1>
                    //                 </div>
                    //             }


                    //         </div>
                    //     </div>
                    // </div >
                })}

            </div>

        </div>
    )
}
