import React, { useState, useEffect } from 'react';
import '../css/Profile.css'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import ProfileGeneral from './ProfileGeneral';
import ProfileAdmin from './ProfileAdmin';
import ProfileOrganizer from './ProfileOrganizer';
import ProfileRefree from './ProfileRefree';
import SignIn from './SignIn';


export default function Profile() {
    const { user } = useUserAuth();
    const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    const navigate = useNavigate();
    const location = useLocation();


    const [signinFlag, setSigninFlag] = useState(false);
    // const [flagLoad, setFlagLoad] = useState(false);
    function setSignIn(flag, userData) {
        console.log(flag)
        setSigninFlag(flag);
        setUserDetails(userData);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    console.log('userDetails : ', userDetails)

    useEffect(() => {
        console.log('userDetails : ', userDetails, '::user::', user)

        if (user.isLoggedIn && userDetails !== null) {
            if (user.userInfo) {
                console.log('userDetails: ', userDetails);
                setSigninFlag(true)
                // setFlagLoad(true);
            }
        }
        else {
            setSigninFlag(false);
            // setFlagLoad(true);
            // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            // navigate("/PhoneSignUp", { state: { url: '' } });
        }
    }, [user, userDetails])

    var getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    // console.log('flagLoad', flagLoad)
    // userDetails = useLocalStorage('userProfile', null);
    // console.log('userDetails.PlayerID', userDetails);
    return (
        <div className='container-fluid'>
            <br></br>
            {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>}

            {<div className='profile-heading-card' style={{ cursor: 'pointer' }} onClick={() => {
                navigate('/EditProfile');
            }}>
                <div className='profile-heading-card-img'>
                    <h1>{userDetails && userDetails.UserName && getInitials(userDetails.UserName)}</h1>
                </div>
                <div className='profile-heading-card-details'>
                    <h2>Hi {userDetails && userDetails.UserName}</h2>
                    <h3>{userDetails && userDetails.Email}</h3>
                </div>
                <div className='profile-heading-card-arrow'>
                    <span className="material-symbols-outlined">
                        arrow_forward_ios
                    </span>
                </div>
            </div>}

            <br></br>

            {userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toUpperCase() === 'PARTICIPANT' && <ProfileGeneral></ProfileGeneral>}
            {userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toUpperCase() === 'ADMIN' && <ProfileAdmin></ProfileAdmin>}
            {userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toUpperCase() === 'ORGANIZER' && <ProfileOrganizer></ProfileOrganizer>}
            {userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toUpperCase() === 'REFREE' && <ProfileRefree></ProfileRefree>}

        </div >
    )
}
