import '../src/App.css';

import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import BeforeNavbar from './components/BeforeNavbar';

import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';
import BottomBar from './components/BottomBar';
import HomePage from './components/HomePage';
import HPGenere from './components/HPGenere'
import HPGrowWithUs from './components/HPGrowWithUs';
import HPGameSection from './components/HPGameSection';
import PartnerSection from './components/PartnerSection';
import Footer from './components/Footer';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import EventEntries from './components/EventEntries';
import EventParticipants from './components/EventParticipants';
import ContactUs from './components/ContactUs';
import More from './components/More';
import FAQ from './components/FAQ';
import UserGuide from './components/UserGuide';
import Calculators from './components/Calculators';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundAndCancellation from './components/RefundAndCancellation';
import Location from './components/Location';
import Clubs from './components/Clubs';
import AboutUs from './components/AboutUs';
import TermsAndConditions from './components/TermsAndConditions';
import EventDetailsLink from './components/EventDetailsLink';
import PlayerParticipation from './components/PlayerParticipation';
import Profile from './components/Profile';
import PhoneSignUp from './components/PhoneSignUp';
import RegisteredProfile from './components/RegisteredProfile';
import ManageParticipant from './components/ManageParticipant';
import RegistrationCategory from './components/RegistrationCategory';
import RegistrationCheckout from './components/RegistrationCheckout';
import PaymentSuccessful from './components/PaymentSuccessful';
import PaymentFailure from './components/PaymentFailure';
import PaymentSuccess from './components/PaymentSuccess';


import { UserAuthContextProvider } from './context/UserAuthcontext';

function App() {
  // const [showFlag, setShowFlag] = useState(true);

  const [city, setCity] = useState();
  function setMyCity(selCity) {
    setCity(selCity);
  }
  const [myEvent, setMyEvent] = useState({
    eventDetails: null,
    entryCount: 0,
    participantCount: 0
  })

  useEffect(() => {
    // console.log(window.location.pathname);
    // if (window.location.pathname === '/PhoneSignUp' || window.location.pathname === '/UserProfile') {
    //   // setShowFlag(false);
    // }
    setCity(window.localStorage.getItem('userLocation') ? window.localStorage.getItem('userLocation') : 'All');
    // console.log('city', city);
  }, [])

  function updateMyEvent(eventDetails, cntEntry, cntPartcipant) {
    setMyEvent({
      eventDetails: eventDetails,
      entryCount: cntEntry,
      participantCount: cntPartcipant
    });
  }

  function setJSON(json) {
    console.log('json : ', json)
  }
  // console.log('window.location.pathname', window.location.pathname);
  return (
    <>
      <BeforeNavbar city={city} />
      <UserAuthContextProvider>
        <Routes>
          <Route exact path='/More' element={<Navbar isFlag={'more'} />} />
          <Route exact path='/Event' element={<Navbar isFlag={'event'} />} />
          <Route exact path='/clubs' element={<Navbar isFlag={'clubs'} />} />
          <Route exact path='/ContactUs' element={<Navbar isFlag={'contactus'} />} />
          <Route exact path='/' element={<Navbar isFlag={'home'} />} />
          <Route path='*' element={<Navbar isFlag={'all'} />} />

        </Routes>
        <Routes>
          <Route path='*' element={<NavbarMobile City={city}></NavbarMobile>} />
        </Routes>

        <BottomBar  ></BottomBar>
        <Routes>
          <Route exact path='/Profile' element={<Profile />} />
          <Route exact path='/PhoneSignUp' element={<PhoneSignUp />} />

          <Route exact path='/Event' element={<Events />} />
          <Route exact path='/ContactUs' element={<ContactUs></ContactUs>}></Route>
          <Route exact path='/More' element={<More />} />
          <Route exact path='/EventDetails' element={<EventDetails />} />
          <Route exact path='/EventDetailsLink/:eventid?' element={<EventDetailsLink />} />
          <Route exact path='/RegisteredProfile' element={<RegisteredProfile />} />

          <Route exact path='/EventEntries' element={<EventEntries />} />
          <Route exact path='/EventParticipants' element={<EventParticipants />} />

          <Route exact path='/PlayerParticipation' element={<PlayerParticipation />} />

          <Route exact path='/FAQ' element={<FAQ></FAQ>}></Route>
          <Route exact path="/UserGuide" element={<UserGuide></UserGuide>} ></Route>
          <Route exact path="/Calculators" element={<Calculators></Calculators>} ></Route>
          <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy></PrivacyPolicy>}></Route>
          <Route exact path='/RefundAndCancellation' element={<RefundAndCancellation></RefundAndCancellation>}></Route>
          <Route exact path='/Location' element={<Location City="All" setCity={setMyCity} />} />
          <Route exact path='/Clubs' element={<Clubs></Clubs>}></Route>
          <Route exact path='/AboutUs' element={<AboutUs></AboutUs>}></Route>
          <Route exact path='/TermsAndConditions' element={<TermsAndConditions></TermsAndConditions>}></Route>
          <Route exact path='/ManageParticipant' element={<ManageParticipant></ManageParticipant>}></Route>
          <Route exact path='/RegistrationCategory' element={<RegistrationCategory></RegistrationCategory>}></Route>
          <Route exact path='/RegistrationCheckout' element={<RegistrationCheckout></RegistrationCheckout>}></Route>
          <Route exact path='/PaymentSuccessful' element={<PaymentSuccessful></PaymentSuccessful>}></Route>
          <Route exact path='/PaymentFailure/:transactionid?' element={<PaymentFailure></PaymentFailure>}></Route>
          <Route exact path='/PaymentSuccess/:transactionid?' element={<PaymentSuccess></PaymentSuccess>}></Route>


          <Route path='/' element={<HomePage updateMyEvent={updateMyEvent} />} />

        </Routes>
        <Routes>
          <Route path='/' element={<HPGenere></HPGenere>} />
        </Routes>
        <section>
          <Routes>
            <Route path='/' element={<HPGrowWithUs></HPGrowWithUs>} />
          </Routes>
        </section>
        <Routes>
          <Route path='/' element={<HPGameSection></HPGameSection>} />
        </Routes>
        <Routes>
          <Route path='/' element={<PartnerSection></PartnerSection>} />
        </Routes>
        <Footer />
      </UserAuthContextProvider>
    </>
  );
}

export default App;
